import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';

import { Box, capitalize, Container } from '@material-ui/core';
import {
  BusinessTwoTone,
  FolderSharedOutlined,
  InfoOutlined,
  LockTwoTone,
} from '@material-ui/icons';
import { useListStyles } from './styled/StudiesViewMakeStyles';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { SectionContainer } from '../Section/styled/StyledSection';
import Loading from '../Common/Loading';
import TableCollapse from '../../componentsUI/TableCollapse';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { GetQueriedTitle, GetQueriedSubtitle } from './StudiesListDetailView';

const fieldNames = [
  { label: 'study.title', id: 'node.title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'created.at', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
];

const getElementHeight = (element) => element && element.getBoundingClientRect().height;

export const StudiesListView = ({
  loading,
  data,
  fetchMoreStudies,
  order,
  handleOrderBy,
  selected,
  handleSelected,
  queryFilters,
  filterElement,
}) => {
  const { t } = useTranslation();
  const classes = useListStyles();
  const [filterHeight, setFilterHeight] = useState(0);

  const updateFilterHeight = () => {
    const height = getElementHeight(filterElement);
    if (height !== filterHeight) setFilterHeight(height);
  };
  setTimeout(updateFilterHeight, 750);

  const handleSelectedItem = (sel) => {
    setFilterHeight(filterHeight - 1);
    handleSelected(sel[0]);
  };

  const GetTitle = (item) => GetQueriedTitle(item, queryFilters);
  const GetSubTitle = (item) => GetQueriedSubtitle(item, queryFilters);

  const getIcon = ({ type }) => {
    switch (type) {
      case 'medicalCase':
        return <FolderSharedOutlined />;
      case 'restricted':
        return <LockTwoTone />;
      case 'hospital':
      default:
        return <BusinessTwoTone />;
    }
  };

  const getEntityType = (item) => {
    if (!item.node) return 'hospital';
    if (item.node.medicalCase) return 'medicalCase';
    if (item.node.restricted) return 'restricted';
    return 'hospital';
  };

  const getCollapse = (object) => {
    if (!object.item) return '';
    if (!object.item.node) return '';

    const getPatientName = (patient) => (`${patient.name} ${patient.surname}`);

    const collapsedInfo = object.item.node.medicalCase ? (
      <>
        <Box>
          <Box component="span">
            {`${capitalize(t('patient'))}: `}
            <Box component="span" fontWeight={700}>
              <Link to={`/patient/${object.item.node.medicalCase.patient.uuid}`}>
                {getPatientName(object.item.node.medicalCase.patient)}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box component="span">
            {`${capitalize(t('medical.case'))}: `}
            <Box component="span" fontWeight={700}>
              <Link to={`/case/${object.item.node.medicalCase.uuid}`}>
                {object.item.node.medicalCase.title}
              </Link>
            </Box>
          </Box>
        </Box>
      </>
    ) : (
      <Box component="span">
        {`${capitalize(t('hospital'))}: `}
        <Box component="span" fontWeight={700}>{object.item.node.hospital.name}</Box>
      </Box>
    );
    return <Box className={classes.collapsedInfo}>{collapsedInfo}</Box>;
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexStudy = data.dicomStudies.edges[index].node;

    navigate(`/study/${indexStudy.uuid}`);
  };

  const StudyDetailsActionButton = {
    Icon: InfoOutlined,
    tooltip: 'view.study.info',
    inProgress: null,
    disabled: false,
  };

  return (
    <Container maxWidth="lg">
      <ScrollableContainer>
        <PageListContent>
          {(loading && isEmpty(data)) ? <Loading /> : (
            <SectionContainer>
              <TableCollapse
                responsive={false}
                fieldNames={fieldNames}
                items={data && data.dicomStudies && data.dicomStudies.edges}
                GetTitle={GetTitle}
                GetSubtitle={GetSubTitle}
                GetIcon={getIcon}
                GetEntityType={getEntityType}
                GetCollapse={getCollapse}
                selected={selected}
                setSelected={handleSelectedItem}
                handleGoto={handleGoto}
                isMore={data
                  && data.dicomStudies
                  && data.dicomStudies.pageInfo
                  && data.dicomStudies.pageInfo.hasNextPage}
                fetchMoreFn={fetchMoreStudies}
                ActionButtons={StudyDetailsActionButton}
                order={order}
                handleOrder={handleOrderBy}
                isSearch
              />
            </SectionContainer>
          )}
        </PageListContent>
      </ScrollableContainer>
    </Container>
  );
};

import React from 'react';
import { Box } from '@material-ui/core';

import { useListStyles } from './styled/StudiesViewMakeStyles';
import { DATE_FORMAT, GetFormat, MarkSearch } from '../../utils/functions';
import { InnerDivHTML, InnerSpanHTML } from '../Common/InnerHTML';

export const GetPatientTitle = (item) => (
  `${item.node.title}`
);

export const GetQueriedTitle = (item, queryFilters) => {
  const description = MarkSearch({
    inputString: item.node.description,
    searchTerm: queryFilters.description,
  });
  const patientId = MarkSearch({
    inputString: item.node.patientId,
    searchTerm: queryFilters.patientId,
  });
  const patientName = MarkSearch({
    inputString: item.node.patientName,
    searchTerm: queryFilters.patientName,
  });
  return (
    <>
      <InnerSpanHTML content={description} />
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <InnerSpanHTML content={patientId} />
      &nbsp;-&nbsp;
      <InnerSpanHTML content={patientName} />
    </>
  );
}

export const GetQueriedSubtitle = (item, queryFilters) => {
  const classes = useListStyles();
  const node = item && item.node;
  const formattedDate = node && node.createdAt && GetFormat(node.createdAt, DATE_FORMAT);
  const modalities = node && node.modalities && Array.isArray(node.modalities)
    ? node.modalities.join(', ')
    : node.modalities;
  const modalityHtml = MarkSearch({
    inputString: modalities,
    searchTerm: queryFilters.modality,
  });
  const studyInfo = item.node.numberOfSeries +
    ' Series · ' +
    item.node.numberOfInstances +
    ' Images · ' +
    modalityHtml;

  return (
    <>
      <Box className={classes.studyDate}>
        {formattedDate}
      </Box>
      <Box className={classes.studyInfo}>
        <InnerDivHTML content={studyInfo} />
      </Box>
    </>
  );
};

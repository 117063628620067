import React, { useState, createRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import { Album, BurstMode, FolderSharedOutlined, OpenInNew, Person } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import LoadMore from '../components/Pagination/LoadMore';
import { useStyles } from './styles/TableCollapse';
import { HeaderFields, ItemFields, ItemFieldsResponsive } from './TableCollapseItems';
import { useWindowSize } from '../utils/useWindowsSize';
import { GetItemType } from '../utils/functions';

const WIDTH_MAX = 960;

const DefaultEntityIcon = ({ type }) => {
  switch (type) {
    case 'Patient':
      return <Person />;
    case 'MedicalCase':
      return <FolderSharedOutlined />;
    case 'DicomStudy':
      return <BurstMode />;
    default:
      return <Album />;
  }
};

const TableCollapse = (props) => {
  const { t } = useTranslation();
  const { ActionButtons, GetTitle, GetSubtitle, GetCollapse, GetIcon, GetEntityType, responsive, openTooltip } = props;
  const { fieldNames, items, selected, setSelected, onDoubleClick, handleGoto, isSearch } = props;
  const { isMore, fetchMoreFn, order, handleOrder } = props;

  const [width] = useWindowSize();
  const isResponsive = (responsive !== undefined) ? responsive : width >= WIDTH_MAX;

  const classes = useStyles(isResponsive);
  const parentRef = createRef(null);
  const [posLeft, setPosLeft] = useState(0);
  const [timer, setTimer] = useState();

  const EntityIcon = ({ type }) => (GetIcon ? <GetIcon type={type} /> : <DefaultEntityIcon type={type} />);
  const getEntityIconType = (item) => (GetEntityType ? GetEntityType(item) : GetItemType(item));

  const handleSelect = (id) => (evt) => {
    evt.stopPropagation();

    if (!timer) {
      if (setSelected) {
        if (selected.includes(id)) {
          setSelected(selected.filter((item) => item !== id));
        } else {
          setSelected([id]);
        }
      }
      setTimer(setTimeout(() => {
        setTimer();
      }, 250));
    } else {
      if (onDoubleClick) onDoubleClick(id);
      clearTimeout(timer);
      setTimer();
    }
  };

  const handleScroll = () => {
    if (-parentRef.current.scrollLeft !== posLeft) {
      setPosLeft(-parentRef.current.scrollLeft);
    }
  };

  const childrenProps = {
    classes,
    isResponsive,
    fieldNames,
    GetTitle,
    GetSubtitle,
    handleGoto,
    posLeft,
    order,
    handleOrder,
  };

  let ActionIconButtons;

  switch (typeof ActionButtons) {
    case 'undefined':
      ActionIconButtons = [{
        Icon: OpenInNew,
        tooltip: openTooltip || 'open',
        inProgress: null,
        disabled: false,
      }];
      break;
    case 'object':
    default:
      ActionIconButtons = Array.isArray(ActionButtons) ? ActionButtons : [ActionButtons];
      break;
  }

  const isButtonDisabled = (button, item) => button && typeof button.disabled === 'function' && button.disabled(item);

  return (
    <div className={classes.table}>
      {!!isResponsive && <HeaderFields {...childrenProps} />}
      <div className={classes.tableItemsScroll} ref={parentRef} onScroll={handleScroll}>
        <div className={classes.tableItems}>

          {items && items.map((item, index) => {
            const type = getEntityIconType(item);
            return (
              <div
                key={index.toString()}
                className={selected.includes(index) ? classes.itemSelected : classes.item}
                onClick={handleSelect(index)}
                aria-hidden="true"
              >
                <div className={classes.tableItem}>
                  {!isSearch && selected.includes(index) && <CheckIcon className={classes.tableItemIcon} />}
                  {!isSearch && !selected.includes(index) && <UnCheckIcon className={classes.tableItemIcon} />}
                  {isSearch && type && <Box className={classes.entity}><EntityIcon type={type} /></Box>}
                  <div style={{ display: 'flex', width: '100%' }}>
                    {isResponsive
                      ? <ItemFields {...childrenProps} index={index} item={item} />
                      : <ItemFieldsResponsive {...childrenProps} index={index} item={item} />}
                  </div>
                  {!!handleGoto
                  && (
                    <div className={classes.goButtonBox}>
                      {!item.noAction ? (ActionIconButtons && ActionIconButtons.map((Button, i) => Button && !isButtonDisabled(Button, item) && (
                        <Tooltip
                          key={i.toString()}
                          title={t(Button.tooltip)}
                          TransitionComponent={Zoom}
                          placement="bottom"
                          arrow
                        >
                          <IconButton
                            className={classes.goButton}
                            disabled={ActionIconButtons.inProgress === index}
                            onClick={(evt) => {
                              if (Button.onClick) {
                                Button.onClick(evt, index);
                                return;
                              }
                              handleGoto(evt, index);
                            }}
                          >
                            <Button.Icon />
                          </IconButton>
                        </Tooltip>
                      ))) : <div style={{ minHeight: 54 }}> </div>}
                    </div>
                  )}
                </div>

                <Collapse className={classes.collapse} in={selected.includes(index)} timeout="auto" unmountOnExit>
                  <div style={{ display: 'flex', maxWidth: '100%' }}>
                    {GetCollapse && <GetCollapse item={item} fieldNames={fieldNames} /> }
                  </div>
                </Collapse>
              </div>
            );
          })}

          {isMore && <LoadMore container parent={parentRef} fetchMoreFn={fetchMoreFn} />}
        </div>
      </div>
    </div>
  );
};

export default TableCollapse;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Chip, Collapse, Container, Grid, Paper } from '@material-ui/core';

import { useFilterStyles } from './styled/StudiesViewMakeStyles';
import { Form } from '../Common/styled/Form';
import { TextFieldUI } from '../../componentsUI/TextField';
import { DateTimeFieldUI } from '../../componentsUI/DateTimeField';
import { GET_STUDIES_MODALITIES } from '../../graphql/queries';
import Loading from '../Common/Loading';
import { AlertUI } from '../../componentsUI/Alert';
import { graphQLErrorParse } from '../../utils/ErrorGraphQLUtils';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import capitalize from "@material-ui/core/utils/capitalize";

const { patientApp } = useApplicationInterface();

export const StudiesFilterView = ({
  attributes,
  filters,
  viewFilters,
  queryFilters,
  reference,
  handleFilters,
  dark,
}) => {
  const { t } = useTranslation();
  const hospital = useSelector((state) => state.hospital);
  const user = useSelector((state) => state.userInterface.user);
  const formRef = useRef();
  const [modalitySelected, setModalitySelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useFilterStyles({ dark });
  const today = moment.tz();
  const defaultStartDate = moment.tz().subtract(1, 'months').format('YYYY-MM-DD');
  const fields = attributes.filter((attribute) => attribute.filter);

  const [getModalities, { loading, data, error }] = useLazyQuery(
    GET_STUDIES_MODALITIES, {
      variables: {
        hospitalUuid: hospital.uuid,
        ...(patientApp && { patientUuid: user.patientUuid }),
        createdAtAfter: moment(filters.fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        createdAtBefore: moment(filters.untilDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ...(Object.keys(queryFilters).length && { filters: queryFilters }),
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => getModalities(), [filters]);

  if (!errorMessage && error && graphQLErrorParse(error)) {
    setErrorMessage(graphQLErrorParse(error));
  }

  const updateFiltersOnLoad = () => {
    if (Object.keys(filters).length) {
      return;
    }
    if (formRef && formRef.current && formRef.current.state) {
      handleFilters(formRef.current.state.values);
    }
  };

  if (errorMessage) {
    return (
      <AlertUI severity="error" title="Error">
        {errorMessage}
      </AlertUI>
    );
  }

  const initialValues = {
    fromDate: filters.fromDate || defaultStartDate,
    untilDate: filters.untilDate || today.startOf('day').format('YYYY-MM-DD'),
  };
  fields.forEach((filter) => {
    initialValues[filter.key] = filters[filter.key] || '';
  });

  updateFiltersOnLoad();

  const modalities = data && data.modalities && data.modalities.sort();
  const modalityInput = filters.modality && filters.modality.trim();

  if (modalitySelected !== modalityInput) {
    setModalitySelected(modalityInput);
  }

  const modalitiesMatching = modalities && modalitySelected && Array.isArray(modalities)
    && modalities.filter((modality) => modality && modality.includes(modalitySelected));

  const modalityClassStyle = (modality) => (modalitiesMatching
    && modalitiesMatching.includes(modality) ? 'selected' : 'default');

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    patientName: Yup.string(),
    patientId: Yup.string(),
    modality: Yup.string(),
    description: Yup.string(),
    fromDate: Yup.date()
      .required(t('required.field'))
      .max(moment(filters.untilDate).add(1,'days').format('YYYY-MM-DD'), t('date.must.be.before.end.date')),
    untilDate: Yup.date()
      .required(t('required.field'))
      .min(moment(filters.fromDate).format('YYYY-MM-DD'), t('date.must.be.after.start.date'))
      .max(today.startOf('day'), t('date.must.be.before.today')),
  });

  const isDateValid = (string) => !isNaN(new Date(string));

  const handleSubmit = async ({ values, errors, setErrors }) => {
    if (errors && Object.keys(errors) && Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    if (!isDateValid(values.fromDate)) return;
    if (!isDateValid(values.untilDate)) return;

    try {
      handleFilters(values);
    } catch (updateError) {
      setErrors(updateError);
    }
  };

  let timer;
  const delayAction = (callback, milliseconds) => { timer = setTimeout(callback, milliseconds); };
  const handleInputChange = (event, props) => {

    if (props.isValidating) return;
    if (!props.isValid) return;

    const attribute = event && event.target && event.target.name;
    if (!attribute) return;

    const update = props;
    update.values[attribute] = event.target.value;
    update.values.modality = update.values.modality.replace(/[0-9]/g, '').toUpperCase();
    formRef.current.setFieldValue('modality', update.values.modality);

    clearTimeout(timer);
    delayAction(() => handleSubmit(update).then(), 1500);
  };

  const handleDateChange = (props, value, attribute) => {

    const update = {
      ...props,
      errors: {},
    };

    update.values[attribute] = moment(value).format('YYYY-MM-DD');

    if (!isDateValid(update.values.fromDate)) {
      update.errors.fromDate = props.errors.fromDate;
    }

    if (!isDateValid(update.values.untilDate)) {
      update.errors.untilDate = props.errors.untilDate;
    }

    handleSubmit(update).then();
  };

  const handleModalityClick = (evt) => {
    let modality = evt.target && evt.target.innerText;
    if (modality === t('all.modalities')) modality = '';

    handleSubmit({
      values: {
        ...formRef.current.state.values,
        modality,
      },
    }).then(setModalitySelected(modality));
  };

  const getFromDateRange = (option) => {
    switch (option) {
      case t('today'):
        return moment.tz();
      case t('week'):
        return moment.tz().subtract(1, 'weeks');
      case t('last.month'):
        return moment.tz().subtract(1, 'months');
    }
  }

  const handleDateRange = (evt) => {
    const option = evt.target.innerText.toLowerCase();
    const updatedFilters = {
      ...filters,
      fromDate: getFromDateRange(option).format('YYYY-MM-DD'),
      untilDate: moment.tz().format('YYYY-MM-DD')
    };
    handleFilters(updatedFilters);
  }

  return (
    <Collapse in={viewFilters} ref={reference}>
      <Container maxWidth="lg">
        <Paper elevation={2} className={classes.box}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            {(props) => (
              <Form
                autoComplete="off"
                id="dicom-search"
                onChange={(event) => handleInputChange(event, props)}
                className={classes.form}
              >
                <Grid container spacing={2} className={classes.form}>
                  {fields && fields.map((filter, index) => (
                    <Grid item key={index.toString()} xs={12} sm={6} md>
                      <TextFieldUI
                        label={t(filter.label)}
                        name={filter.key}
                        variant="outlined"
                        props={props}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} className={classes.form}>
                  <Grid item xs={12} sm={6} md={3}>
                    <DateTimeFieldUI
                      name="fromDate"
                      maxDate={filters.untilDate}
                      disableFuture
                      iconPosition="right"
                      label={t('studies.from')}
                      props={props}
                      classes={classes}
                      handleChange={(value) => handleDateChange(props, value, 'fromDate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DateTimeFieldUI
                      name="untilDate"
                      minDate={filters.fromDate}
                      disableFuture
                      iconPosition="right"
                      label={t('studies.to')}
                      props={props}
                      classes={classes}
                      handleChange={(value) => handleDateChange(props, value, 'untilDate')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.modalities}>
                      {loading && !data && <Loading />}
                      <Chip color='primary' label={capitalize(t('last.month'))} onClick={handleDateRange} />
                      <Chip color='primary' label={capitalize(t('week'))} onClick={handleDateRange} />
                      <Chip color='primary' label={capitalize(t('today'))} onClick={handleDateRange} />
                      {modalities && (
                        <Chip label={t('all.modalities')} onClick={handleModalityClick} />
                      )}
                      {modalities && modalities.map((modality, index) => (
                        <Chip
                          key={index.toString()}
                          label={modality}
                          onClick={handleModalityClick}
                          className={modalityClassStyle(modality)}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </Collapse>
  );
};

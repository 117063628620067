import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import { useGridStyles } from './styled/StudiesViewMakeStyles';
import { InnerSpanHTML } from '../Common/InnerHTML';
import { GetFormat, MarkSearch } from '../../utils/functions';
import Loading from '../Common/Loading';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export const StudiesGridTable = ({
  attributes,
  items,
  loading,
  selected,
  handleGoto,
  handleSelected,
  SelectedIcon,
  defaultRowCellStyle = 'default',
  dark = false,
  queryFilters,
}) => {
  const { t } = useTranslation();
  const [sentinel, setSentinel] = useState();
  const [paperBoxSentinel, setPaperBoxSentinel] = useState();
  const [lastColumnSentinel, setLastColumnSentinel] = useState();
  const styles = { offsetY: sentinel && sentinel.getBoundingClientRect().y, dark };
  const classes = useGridStyles(styles);
  const columns = attributes.filter((attribute) => !attribute.hidden);

  const rows = items && items.map((item) => ({
    ...item,
    modality: item && item.modalities && Array.isArray(item.modalities) ? item.modalities.join(', ') : item.modalities,
  }));

  const handleClick = (event, index, row, cell) => {
    event.stopPropagation();

    if (row.disabled) return null;

    return cell === 0
      ? handleSelected(index)
      : handleGoto(index);
  };

  const CellContent = ({ column, row, itemSelected }) => {

    switch (column.key) {
      case 'select':
        return <SelectedIcon selected={itemSelected} />;
      case 'open':
        return <OpenInNew />;
      default:
        const searchQuery = {
          inputString: row[column.key],
          searchTerm: queryFilters[column.key],
        }
        return <InnerSpanHTML content={GetFormat(MarkSearch(searchQuery), column.format)} />;
    }
  };

  const tableRowClassname = (row) => (row.disabled ? `${classes.rows} disabled` : classes.rows);

  const tableCellClassname = (cell) => {
    switch (cell) {
      case 0:
        return 'actions';
      default:
        return defaultRowCellStyle;
    }
  };

  const getLastColumnWidth = (defaultWidth) => {
    const paperBoxRect = paperBoxSentinel && paperBoxSentinel.getBoundingClientRect();
    const lastColumnRect = lastColumnSentinel && lastColumnSentinel.getBoundingClientRect();

    if (!paperBoxRect || !lastColumnRect) return defaultWidth;

    return paperBoxRect.right - lastColumnRect.left > defaultWidth
      ? paperBoxRect.right - lastColumnRect.left - 24
      : defaultWidth;
  };

  const defaultWidth = columns && columns.length && columns[columns.length - 1] && columns[columns.length - 1].width;

  const [lastColumnWidth, setLastColumnWidth] = useState(defaultWidth);

  useEffect(() => {
    setLastColumnWidth(getLastColumnWidth(defaultWidth));
  }, [items]);

  if (items && !items.length) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="info" title={t('dicom.studies')}>
          {t('no.studies.found')}
        </AlertUI>
      </AlertWrapperUI>
    );
  }

  const cellStyle = (column) => ({ width: column.width, textAlign: column.align || 'left' });

  return (
    <Box component={Paper} className={classes.tableContainer} ref={(i) => setPaperBoxSentinel(i)}>
      {loading && !items && (
        <Box className="loading">
          <Loading />
        </Box>
      )}
      <TableContainer className={classes.tableWrapper}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns && columns.map((column, index) => (
                index === columns.length - 1 ? (
                  <TableCell
                    key={index.toString()}
                    style={{
                      ...cellStyle(column),
                      width: lastColumnWidth,
                    }}
                    ref={(i) => setLastColumnSentinel(i)}
                  >
                    {t(column.label)}
                  </TableCell>
                ) : (
                  <TableCell key={index.toString()} style={cellStyle(column)}>
                    {t(column.label)}
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={(i) => setSentinel(i)} className={classes.body}>
            {rows && rows.map((row, index) => {
              const itemSelected = !!selected.length && selected.includes(index);
              return (
                <TableRow
                  role="checkbox"
                  aria-checked={itemSelected}
                  key={index.toString()}
                  className={tableRowClassname(row)}
                  tabIndex={-1}
                >
                  {columns && columns.map((attr, cell) => (
                    <TableCell
                      key={cell.toString()}
                      className={tableCellClassname(cell)}
                      style={cellStyle(attr)}
                      onClick={(event) => handleClick(event, index, row, cell)}
                    >
                      <CellContent
                        column={attr}
                        row={row}
                        itemSelected={itemSelected}
                        queryFilters={queryFilters}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            <TableRow className={classes.last}>
              {columns && columns.map((column, index2) => (
                <TableCell key={index2.toString()}>&zwnj;</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
